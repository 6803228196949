var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E 6B",
      "8": "F",
      "292": "A B"
    },
    B: {
      "1": "M N O P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H",
      "292": "C K L G"
    },
    C: {
      "1": "RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB",
      "2": "7B qB I r J D E F A B C K L G M N O 8B 9B",
      "8": "0 1 2 3 4 5 6 7 8 9 s t u v w x y z AB BB CB",
      "584": "DB EB FB GB HB IB JB KB LB MB NB OB",
      "1025": "PB QB"
    },
    D: {
      "1": "VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC",
      "2": "I r J D E F A B C K L G M N O s t u v w x",
      "8": "0 1 y z",
      "200": "2 3 4 5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB",
      "1025": "UB"
    },
    E: {
      "1": "B C K L G xB nB oB yB HC IC zB 0B 1B 2B pB 3B JC KC",
      "2": "I r CC wB DC",
      "8": "J D E F A EC FC GC"
    },
    F: {
      "1": "HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b",
      "2": "0 F B C G M N O s t u v w x y z LC MC NC OC nB 4B PC oB",
      "200": "1 2 3 4 5 6 7 8 9 AB BB CB DB EB FB GB"
    },
    G: {
      "1": "YC ZC aC bC cC dC eC fC gC hC iC jC zB 0B 1B 2B pB 3B",
      "2": "wB QC 5B RC",
      "8": "E SC TC UC VC WC XC"
    },
    H: {
      "2": "kC"
    },
    I: {
      "1": "H",
      "2": "qB I lC mC nC oC",
      "8": "5B pC qC"
    },
    J: {
      "2": "D A"
    },
    K: {
      "1": "d",
      "2": "A B C nB 4B oB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "292": "A B"
    },
    O: {
      "1": "rC"
    },
    P: {
      "1": "tC uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C",
      "2": "sC",
      "8": "I"
    },
    Q: {
      "1": "yB"
    },
    R: {
      "1": "5C"
    },
    S: {
      "1": "6C"
    }
  },
  B: 4,
  C: "CSS Grid Layout (level 1)"
};
export default exports;